<template>

<div style="max-width: 800px">

  <div class="card-box">
    <h4>Reset Phone Numbers</h4>
    <p>Bulk reset the reputation for phone numbers</p>
    <CButton @click="loadPath('reputation/reset-phone-numbers')" color="info" class="card-box-button">
      Reset Phone Numbers
    </CButton>
  </div>


</div>
</template>

<script>
export default {
  name: 'Reputation',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
